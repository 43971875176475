import { takeLatest, put, all } from 'redux-saga/effects';

import api from 'apiSingleton';

import * as actionTypes from 'redux/payment/actionTypes';
import { getMerchantData as getMerchantDataAction, setLoadingStatus, setMerchantData } from 'redux/payment/actions';

import { MerchantDataResponseType } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';

export function* getMerchantDataSaga({ payload }: ActionType<typeof getMerchantDataAction>) {
    try {
        yield put(setLoadingStatus(true));
        const merchantData: MerchantDataResponseType = yield api.payment.getMerchantData(payload);

        yield all([
            put(
                setMerchantData({
                    merchant: merchantData?.merchant,
                    signature: merchantData?.signature,
                    paymentIntent: merchantData?.payment_intent,
                })
            ),
            put(setLoadingStatus(false)),
        ]);
    } catch (e) {
        throw e;
    }
}

export const getMerchantData = [takeLatest(actionTypes.GET_MERCHANT_DATA, getMerchantDataSaga)];
